import axiosConfig from "./axiosConfig";
import {toast} from "react-toastify";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const reviews = [
    {
        "ID": 10,
        "Aktiv": "Y",
        "Beschreibung": "Gute Auswahl der Ausflüge, auch in ihrer Reihenfolge. Alle gebuchten Leistungen waren gut. Gesamteindruck: 1.",
        "Verfasser": "Ludwig Berchthold, Raiffeisen-Reisen",
        "ReiseID": 458,
        "Reise": "Kroatien",
        "InsertAm": "2013-05-29 16:12:27",
        "InsertVon": null
    },
    {
        "ID": 11,
        "Aktiv": "Y",
        "Beschreibung": "Es war richtig gut. Suuper Reiseleiterin, suuuper Busfahrer, suuuper Hotel, ungewöhnlich nettes Personal im Hotel, das Essen sauber und sehr gut - war wohl meine beste Reise. Ich würde an Ihrer Stelle NUR noch Kroatien verkaufen!!!",
        "Verfasser": "Marion Wulf-Nixdorf, Mecklenburgische & Pommersche Kirchenzeitung",
        "ReiseID": 458,
        "Reise": "Kroatien",
        "InsertAm": "2013-05-29 16:12:27",
        "InsertVon": null
    },
    {
        "ID": 24,
        "Aktiv": "Y",
        "Beschreibung": "... die Reise war klasse und wir hatten alle viel Spaß! Ihr Reiseleiter Antonio ist erstklassig. Kompliment auch für Hotelauswahl und Organisation.",
        "Verfasser": "Angelika Stanglmayr, Teilnehmerin aus München",
        "ReiseID": 437,
        "Reise": "Kuba ",
        "InsertAm": "2013-05-29 16:22:28",
        "InsertVon": null
    },
];
export const allTripTypes = [
    {
        title: 'Flugreisen',
        description: 'Innerhalb Europas oder nach Übersee – wir bringen Sie zusammen mit unseren Partner-Airlines zu attraktiven Reisezielen rund um den Globus. Buchen Sie jetzt Ihren Flug in den Traumurlaub!',
        image: `${process.env.PUBLIC_URL}/images/destAndTypes/flugreisen.png`,
        bgImage: process.env.PUBLIC_URL + "/images/headers/Flugreisen-Blauerhimmel-mit-Flugzeug.png"
    },
    {
        title: 'Flusskreuzfahrten',
        description: 'Ob Rhein, Seine oder Donau – entlang ihrer Windungen ruhen historische Altstädte und wunderschöne Naturlandschaften. Genießen Sie die malerische Vielfalt der blauen Wasserstraßen und entdecken Sie mit unserem Partner Lüftner Cruises die schönsten Flussrouten Europas. ',
        image: `${process.env.PUBLIC_URL}/images/destAndTypes/flusskreuzfahrten.png`,
        bgImage: process.env.PUBLIC_URL + "/images/headers/Kreuzfahrten-Kreuzfahrt-in-tiefblauem-Meer.png"
    },
    {
        title: 'Hochseekreuzfahrten',
        description: 'Stechen Sie in See mit uns und unseren Hochsee-Partnern „Sea Cloud Cruises“ sowie „Hapag-Lloyd Cruises“. Das maritime Panorama der Ozeane und Meere wartet auf Sie. Dabei legen wir an einigen der schönsten Häfen der Welt an. Kommen Sie an Bord und genießen Sie die Faszination Kreuzfahrt.',
        image: `${process.env.PUBLIC_URL}/images/destAndTypes/hochseekreuzfahrten.png`,
        bgImage: process.env.PUBLIC_URL + "/images/headers/Hochseereise-Sonnenuntergang-in-Nordgroenland.png"
    },
    {
        title: 'Kultur- und Eventreisen',
        description: 'Freuen Sie sich auf unvergessliche Konzerte, Festspiele und Kultur-Events.   Unsere Kultur- und Eventreisen bieten ein Kontrastprogramm für alle, die einzigartige Reisemomente fernab des Alltags wünschen.',
        image: `${process.env.PUBLIC_URL}/images/destAndTypes/kultur-und-event-reisen.png`,
        bgImage: process.env.PUBLIC_URL + "/images/headers/kultur-eventreisen-panorama-von-salzburg.png"
    },
    {
        title: 'Wellness und Vitalreisen',
        description: 'Erholen Sie Körper und Geist während unserer Wellness- und Vitalreisen. Hier ist der Wohlfühlfaktor Programm. Sie nächtigen in Wellnesshotels und können die Vorteile eines revitalisierenden Reiseprogramms genießen. Tauchen Sie ein in eine Welt aus Entspannung und Wellness!',
        image: `${process.env.PUBLIC_URL}/images/destAndTypes/wellness-und-vitalreisen.png`,
        bgImage: process.env.PUBLIC_URL + "/images/headers/wellness-vitalreisen-therme-mit-ausblick.png"
    },
    {
        title: 'Busreisen',
        description: 'Alternativ zu unseren Flugangeboten können Sie bei uns ausgewählte Busreisen innerhalb Deutschlands und Europas buchen. Lassen Sie sich von Urlaubszielen in komfortabler Reisenähe verzaubern.',
        image: `${process.env.PUBLIC_URL}/images/destAndTypes/busreisen.png`,
        bgImage: process.env.PUBLIC_URL + "/images/headers/busreisen-bus-bei-sonnenuntergang.png"
    },
    {
        title: 'Reisen mit Eigenanreise',
        description: 'Unsere Reisen mit Eigenanreise ermöglichen Ihnen eine individuelle Anfahrt zur Destination. Planen Sie die Anreise nach eigenen Wünschen und genießen Sie anschließend die Vorzüge unseres jeweiligen Reiseprogramms vor Ort.',
        image: `${process.env.PUBLIC_URL}/images/destAndTypes/reisen-mit-eigenanreise.png`,
        bgImage: process.env.PUBLIC_URL + "/images/headers/Reisen-mit-Eigenanreise-Hamburger-Innenstadt-mit-Rathaus-und-Alster.png"
    }
]

export const allTripDestinations = [
    {
        id: "0",
        title: 'Südeuropa',
        shortDescription: 'Reisen Sie mit uns nach Südeuropa. Dort, wo die Sonne und der mediterrane Charme zu Hause sind. Zwischen Urlaubserholung und exklusiven Ausflügen lernen Sie die Mittelmeerregion von ihrer idyllischen Seite kennen.',
        description: 'Azurblaue Küstenlandschaften und malerische Hafenstädte prägen Südeuropa: ob die portugiesischen Azoren oder die Inselwelten Griechenlands. Entdecken Sie UNESCO-Welterbestätten sowie antike Architektur. Und besuchen Sie berühmte Sehenswürdigkeiten auf unseren exklusiven Ausflügen. In Südeuropa fühlen sich Sonne, Meer und mediterranes Flair zu Hause. Punkten kann die Region nicht zuletzt mit außergewöhnlichen kulinarischen Spezialitäten. Buchen Sie jetzt und erleben Sie die kulturelle und natürliche Schönheit Südeuropas.',
        image: process.env.PUBLIC_URL + "/images/destAndTypes/suedeuropa-valetta.png",
        bgImage: process.env.PUBLIC_URL + "/images/headers/Suedeuropa-Blick-auf-Valetta.png",
        continent: 1
    },
    {
        id: "1",
        title: 'Nord- und Mitteleuropa',
        shortDescription: 'Zwischen Skandinavien und den Alpen ruhen außergewöhnliche Altstädte mit   UNESCO-Weltkulturerbe, wunderschöne Flusslandschaften und charmante Ausflugsziele. Entdecken Sie mit uns Nord- und Mitteleuropa aus einer neuen Perspektive. Während der Reise profitieren Sie von unseren exklusiven Ausflugsprogrammen.',
        description: 'Von den Alpen bis zu den Fjorden – Nord- und Mitteleuropa beheimatet außergewöhnliche Naturlandschaften. Auf unseren Reisen entdecken Sie die Facetten dieser sehenswerten europäischen Region. Historische Altstädte, UNESCO-Weltkulturerbe, malerische Naturpanoramen: erleben Sie Nord- und Mitteleuropa von ihrer besonderen Seite. Die Destinationen dieser Kategorie reichen von Großbritannien bis zum Baltikum, von Skandinavien bis nach Frankreich. Weit reisen müssen Sie allerdings nicht, schließlich können Sie auf unseren Fluss- und Städtereisen auch innerhalb Deutschlands schöne Regionen kennenlernen. Buchen Sie jetzt Ihre Reise und erkunden Sie die touristischen Höhepunkte Nord- und Mitteleuropas.',
        image: process.env.PUBLIC_URL + "/images/destAndTypes/nord-undmitteleuropa-jersey.png",
        bgImage: process.env.PUBLIC_URL + "/images/headers/Nord-und-Mitteleuropa-Blick-auf-Gorey.png",
        continent: 2
    },
    {
        id: "2",
        title: 'Orient',
        shortDescription: 'Der besondere Charme des Orients beherbergt sehenswerte Destinationen. Bereisen Sie Länder wie die Türkei, Ägypten oder Dubai. Und tauchen Sie mit uns in ein unvergessliches Reiseerlebnis ein.',
        description: 'Der außergewöhnliche Zauber des Orients inspiriert zum Reisen. Mit uns können Sie Highlights der faszinierenden Region persönlich entdecken. Besuchen Sie sehenswerte Länder wie die Türkei, Ägypten oder Dubai. Vor Ihren Augen entfaltet sich die Faszination einer jahrtausendealten Geschichte zwischen monumentalen Pyramiden, historischen Moscheen und lebhaften Basaren. Mit seinen Wüsten und Oasen übt der Orient unter Reisenden eine magnetische Anziehung aus. Komfortable Hotels und exklusive Ausflugspakete runden das Erlebnis mit uns ab. Begleiten Sie uns auf eine Reise in den Orient!',
        image: process.env.PUBLIC_URL + "/images/destAndTypes/orient-usbekistan.png",
        bgImage: process.env.PUBLIC_URL + "/images/headers/Orient-Usbekistan.png",
        continent: 8
    },
    {
        id: "3",
        title: 'Fernreisen',
        shortDescription: 'Ob Amerika, Asien oder Afrika – Fernreisen mit uns begeistern durch traumhafte Destinationen außerhalb Europas. Entdecken Sie touristische Highlights auf fernen Kontinenten!',
        description: 'Begleiten Sie uns auf Entdeckungstour zu den entfernten Orten der Welt und lernen Sie andere Kontinente kennen. Ob Amerika, Asien oder Afrika – füllen Sie Ihren Schatz aus unvergesslichen Reiseerlebnissen auf. Fernreisen richten sich an Globetrotter, die den außergewöhnlichen Charme anderer Kulturen erleben möchten. Dabei runden abwechslungsreiche Ausflugsziele und komfortable Unterkünfte das exklusive Reiseerlebnis mit uns ab. Machen Sie Ihren Reisetraum von anderen Kontinenten zur Wirklichkeit!',
        image: process.env.PUBLIC_URL + "/images/destAndTypes/fernreisen-suedafrika.png",
        bgImage: process.env.PUBLIC_URL + "/images/headers/Fernreisen-Elefantenbabys-in-Afrika.png",
        continent: 9
    }
]

export const recommendation = [
    {
        id: "0",
        cat: "Empfehlungen",
        date: "03.04.2024",
        title: 'Südeuropa',
        description: 'Einsame Buchten mit azurblauem Wasser, historische Küstenstädte und sommerlich-mediterranes Flair - die sonnenverwöhnte Region Dalmatien lockt mit einer Fülle an Naturschönheiten und einzigartigen Kulturschätzen unterschiedlichster Epochen. Hier vor der majestätischen Kulisse der Küstengebirge in Kroatiens Süden laden unzählige Adria-Inseln mit langen Kieselstränden und duftenden Kiefernwäldern zu einem vielfältigen Kultur- und Badeurlaub. Die Highlights Dalmatiens lassen sich am besten per Schiff erkunden und so steuern Sie auf dieser siebentägigen Kreuzfahrt an Bord der San Antonio weltberühmte Welterbestätten ebenso an wie naturbelassene Eilande. Die moderne, im Jahr 2018 erbaute Yacht bietet mit 18 komfortablen Kabinen Platz für einen entspannten Urlaub und verfügt über einen großen Salon mit Bar und eine aussichtsreiche Outdoor-Lounge mit Sonnenliegen und Whirlpool für entspannte Stunden an Deck. Auf dem Reiseplan stehen lebhafte Zentren wie Split und Dubrovnik, die mit ihren UNESCO-Welterbe-Altstädten begeistern, dazwischen sorgen Abstecher auf idyllische Inseln wie Mljet oder Hvar und zahlreiche Badestopps in malerischen Buchten für Entspannung. Über Nacht liegt das Schiff stets im Hafen oder in einer ruhigen Bucht.',
        img: `${process.env.REACT_APP_IMG_PATH}/Kroatien_1911211954_low.jpg`,
        continent: 1
    },
    {
        id: "3",
        cat: "Empfehlungen",
        date: "19.05.2024",
        title: 'Fernreisen',
        description: 'Südafrika - das Land an der Südspitze der Welt - zählt weltweit zu den beliebtesten Reisezielen. Wir laden Sie ein, es hautnah und exklusiv mit uns zu erleben. Traumhafte Küsten, atemberaubende Panoramen und einzigartige Landschaften entlang der Garden Route warten darauf von Ihnen entdeckt zu werden. Seien Sie gespannt auf die unvergleichliche Tierwelt Südafrikas und erleben Sie diese aus nächster Nähe während Ihrer Safari durch den weltberühmten Kruger Nationalpark. Löwen, Büffel, Elefanten, Leoparden, Nashörner und viele mehr - hier haben Sie beste Chancen, Afrikas »Big Five« vor die Kamera zu bekommen. Jene fünf Großsäuger sind sozusagen das Aushängeschild des Parks, der mit einer Ausdehnung von über 19.000 Quadratkilometern nicht nur der größte Nationalpark Südafrikas ist, sondern auch zu den größten und bekanntesten Wildtierschutzgebieten der Welt zählt. Nach Ihrer Safari fliegen Sie von Johannesburg nach Kapstadt und erleben die pulsierende und weltoffene Metropole, für viele eine der schönsten Städte der Welt. Traumhafte Strände, das berühmte Kap der Guten Hoffnung, die malerischen Weingegend und natürlich der sagenhafte Tafelberg bilden eine traumhafte Kulisse und lassen jedes Urlauberherz höherschlagen. Nur wenige Länder bieten eine solche Vielfalt an Naturschönheiten, gepaart mit einer Tierwelt, die ihresgleichen sucht. Europäischer Komfort, kapholländische Geschichte und die Ursprünglichkeit des afrikanischen Kontinents bilden in Südafrika eine einmalige Symbiose, die Sie begeistern wird: Eine ganze Welt in einem Land erwartet Sie!',
        img: `${process.env.REACT_APP_IMG_PATH}/Suedafrika_Kruger_1908191147_low.jpg`,
        continent: 9
    }
]

export const monthNames = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
];

export const mapTripType = (type = "") => {
    switch (type) {
        case "Flugreisen":
            return "Fluganreise"
        case "Flusskreuzfahrten": // todo
            return "Flussreise"
        case "Hochseekreuzfahrten":
            return "Hochsee-Kreuzfahrt"
        case "Kultur- und Eventreisen":
            return "Standortreise mit Kultur-Event,Rundreise mit Kultur-Event"
        case "Wellness und Vitalreisen":
            return "Wellness- und Vital-Reise"
        case "Busreisen":
            return "Busanreise"
        case "Reisen mit Eigenanreise":
            return "Eigenanreise"
        default:
            return ""
    }
}

export const fetchDestinations = async () => {
    try {
        const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/destinations`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return []
    }
};

// Function to fetchTypes using Axios
export const fetchTypes = async () => {
    try {
        const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/types`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        return []
    }
};


export const fetchType = async (currentType) => {
    try {
        const response = await axiosConfig.get("/types/" + currentType);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        return []
    }
};

export const fetchBestsellerTrips = async (limit = 8) => {
    try {
        const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/trips?limit=${limit}`);
        return response.data.data;
        //setBestSellerTrips(response.data.data);
        //setIsLoading(false);
    } catch (error) {
        //console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchDealsTrips = async (limit = 4) => {
    try {
        const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/trips?limit=${limit}`);
        return response.data.data;
        //setDealsTrips(response.data.data);
        //setIsLoading(false);
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchDiscountOffer = async () => {
    try {
        const response = await axiosConfig.get(`/coupon/offer`);
        return response.data;
        //setDealsTrips(response.data.data);
        //setIsLoading(false);
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchCalendarTrips = async (month, year, type, dest, search) => {
    try {
        const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/calendar-trips?month=${month}&year=${year}&type=${type}&dest=${dest}&search=${search}`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchTransactionAccommodations = async (transactionNo) => {
    try {
        const response = await axiosConfig.get(`/transactions/${transactionNo}/accommodations`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchTransactionsDetails = async (transactionNo) => {
    try {
        const response = await axiosConfig.get(`/transactions/details/${transactionNo}`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchBookingGrundpreis = async (transactionNo) => {
    try {
        const response = await axiosConfig.get(`/booking/grundpreis/${transactionNo}`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};


export const fetchIncludedServices = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/services?type=1 - INKLUSIV`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchBookingAdvancedServices = async (transactionNo) => {
    try {
        const response = await axiosConfig.get(`/booking/services/${transactionNo}`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};

export const fetchCountries = async () => {
    try {
        const response = await axiosConfig.get(`/countries`);
        return response.data
    } catch (error) {
        console.error("Error fetching data:", error);
        return [];
    }
};


export const fetchTrip = async (tripId, active = true) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}?active=${active}`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchContingent = async (currentCont) => {
    try {
        const response = await axiosConfig.get("/contingents/" + currentCont);
        return response.data
    } catch (error) {
        throw error
    }
};

export const fetchExcludedServices = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/services?type=3 - F_VORAB_BUCHBAR`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchLocalServices = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/services?type=4 - F_VORORT_BUCHBAR,5 - F_VORORT_ZAHLBAR`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchNotIncludedServices = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/services?type=6 - F_ALLGEMEIN,2 - ZUSCHLAG`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchTripSeeings = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/seeings`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};


export const fetchTripReferences = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/references`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchTripAppointments = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/appointments?agencyNo=${process.env.REACT_APP_AGENCY_NO}`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchTripAppointment = async (tripId, transactionId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/appointment/${transactionId}`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchTripAccommodations = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/accommodations?agencyNo=${process.env.REACT_APP_AGENCY_NO}`);
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};

export const fetchRelatedTrips = async (tripId) => {
    try {
        const response = await axiosConfig.get(`/trips/${tripId}/related?agencyNo=${process.env.REACT_APP_AGENCY_NO}`);
        return response.data.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
};


export const fetchAgency = async (agencyNo, token) => {
    try {
        const response = await axiosConfig.get(`/protected/agency/${agencyNo}`,
            {
                "headers": {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }
        );
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
}


export const fetchBooked = async (agencyNo, token) => {
    try {
        const response = await axiosConfig.get(`/protected/agency/${agencyNo}/booked`,
            {
                "headers": {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }
        );
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
}

export const fetchContingentByAgency = async (agencyNo, token) => {
    try {
        const response = await axiosConfig.get(`/protected/agency/${agencyNo}/contingents`,
            {
                "headers": {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }
        );
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
}

export const fetchContactPersonsByAgency = async (agencyNo, token) => {
    try {
        const response = await axiosConfig.get(`/protected/agency/${agencyNo}/contact-persons`,
            {
                "headers": {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            }
        );
        return response.data
    } catch (error) {
        //console.error("Error fetching data:", error);
        throw error
    }
}


export const nl2br = (str, is_xhtml = 'undefined') => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}


export const moka = (accommodations) => {
    // remove no selected
    for (const key in accommodations) {
        if (!accommodations[key].value) {
            accommodations.splice(key, 1);
        }
    }

    const rooms = []
    accommodations?.forEach((accommodation, key) => {
        const occupations = []
        for (let b = 1; b <= Math.ceil(accommodation.belegung); b++) {
            occupations.push(b)
        }

        for (let n = 1; n <= Math.ceil(accommodation.value); n++) {
            rooms.push({accommodation, occupations})
        }
    });
    //console.log(rooms)
    return rooms;

}

export const formatCurrency = (price = 0) => {
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', minimumFractionDigits: 0}).format(price);
};

export const notify = (message, type = 'error') => {
    if (type === "error") {
        toast.error(message, {
            position: "top-center",
            className: "p-5"
        });
    } else if (type === "warning") {
        toast.warning(message, {
            position: "top-center",
            className: "p-5"
        });
    } else {
        toast.success(message, {
            position: "top-center",
            className: "p-5"
        });
    }
};

export const truncateText = (str, n) => {
    return str.length > n ? <>{str.substring(0, n)}&hellip;</> : str;
}


export const formatPhoneNumber = (number = "") => {
    if (number === "" || number === undefined) {
        return "+4961874804840";
    }

    let phone = number.replace(/^0+/, '')
    phone = "+49" + phone.replace(/[^0-9.]/g, '');
    return phone;
}

export const getPhoneNumber = (number = "") => {
    if (number === "" || number === undefined) {
        return "06187 / 48 04 840";
    }

    return number;
}


export const localizeDate = (date) => {
    let d = new Date(date);
    const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };

    return d.toLocaleDateString("de", options)
        .replace(',', '');

}

export default function ScrollToTop() {
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        });
    }, [pathname]);

    return null;
}

export const diffYears = (dt2, dt1) => {
    dt1 = new Date(dt1);
    dt2 = new Date(dt2);
    // Calculate the difference in milliseconds between the two dates
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    // Convert the difference from milliseconds to days
    diff /= (60 * 60 * 24);
    // Calculate the approximate number of years by dividing the difference in days by the average number of days in a year (365.25)
    return Math.abs(Math.round(diff / 365.25));
}