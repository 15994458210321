import './App.css';
import {Route, Routes, useNavigate} from 'react-router-dom';
import Footer from "./components/partial/Footer";
import Main from './components/Main';

// filename app.js
import "bootstrap/dist/js/bootstrap.bundle.min";

import './scss/default.scss';
import React, {useEffect, useState} from "react";
import "jquery"
import About from "./components/About";
import RKV from "./components/rkv";
import Condition from "./components/Condition";
import Payment from "./components/payment";
import FAQ from "./components/faq";
import Privacy from "./components/privacy";
import Imprint from "./components/imprint";
import Contact from "./components/Contact";
import Career from "./components/Career";
import References from "./components/References";
import AllDestination from "./components/AllDestination";
import Type from "./components/Type";
import AllTypes from "./components/AllTypes";
import Finder from "./components/Finder";
import Trip from "./components/Trip";
import Destination from "./components/Destination";
import Calendar from "./components/calendar";
import Checkout from "./components/Checkout";
import Booked from "./components/partial/booking/Booked";
import NotFound from "./components/NotFound";
import NewsletterConfirm from "./components/NewsletterConfirm";
import NewsletterUnsubscribe from "./components/NewsletterUnsubscribe";
import axiosConfig, {AxiosInterceptor} from "./components/utils/axiosConfig";
import Nav from "./components/partial/Nav";
import ScrollToTop from "./components/utils/utils";
import ProtectedRoute from "./context/ProtectedRoute";
import Login from "./components/account/Login";
import Dashboard from "./components/account/Dashboard";
import AuthProvider from "./context/AuthContext";
import DraftTrip from "./components/draft-trip/DraftTrip";
import Logout from "./components/account/Logout";


function App() {

    const navigate = useNavigate();

    // todo load based on the subdomain
    const domain = window.location.hostname
    const currentUrl = window.location.href
    //console.log(domain)

    const [showNavTopBar, setShowNavTopBar] = useState(false);
    const [addClass, setAddClass] = useState("fixed-top");
    const [partner, setPartner] = useState({
        "id": 33334,
        "name": "globalis",
        "description": null,
        "domain": "globalis.de",
        "email": "info@globalis.de",
        "agency_no": 33334,
        "header_logo": "empty-logo.svg",
        "footer_logo": "empty-logo.svg",
        "main_color": "#FFF",
        "secondary_color": "#FFF"
    });

    const fetchPartner = async () => {
        try {
            const response = await axiosConfig.get(`/partners/agency/${process.env.REACT_APP_AGENCY_NO}`);
            setPartner(response.data);
            // todo: make this over the config table
            if (response.data.agency_no === 33334) { //
                import((`./scss/globalis.scss`));
            }

            // SZ.GLOBALIS.DE
            if (response.data.agency_no === 91543) {
                setShowNavTopBar(true);
                setAddClass('');
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const root = document.documentElement;
    if (partner.main_color) {
        root?.style.setProperty("--main-color", partner.main_color)
    }
    if (partner.secondary_color) {
        root?.style.setProperty("--secondary-color", partner.secondary_color)
    }

    useEffect(() => {
        // todo: delete
        if (currentUrl.includes("GN_Reisekalender")) {
            navigate("/reisekalender");
            window.location.reload();
        } else if (currentUrl.includes("GN_ALLE")) {
            navigate("/reisefinder");
            window.location.reload();
        } else if (currentUrl.includes("GN_Flug")) {
            navigate("/reisefinder");
            window.location.reload();
        } else if (currentUrl.includes(".php")) {
            navigate("/");
            window.location.reload();
        }

        fetchPartner();
        document.title = partner.name.toUpperCase();
    }, [partner.name]);
    return (
        <>
            <ScrollToTop></ScrollToTop>
            <AuthProvider>
                <AxiosInterceptor>
                    <Nav partner={partner} showNavTopBar={showNavTopBar} addClass={addClass}/>
                    <Routes>
                        <Route path="/" element={<Main/>}/>
                        <Route path="/globalis" element={<About partner={partner}/>}/>
                        <Route path="/reisefinder" element={<Finder/>}/>

                        <Route path="/reiseziele" element={<AllDestination partner={partner}/>}/>
                        <Route path="/reiseziele/:dest" element={<Destination partner={partner}/>}/>
                        <Route path="/reisearten" element={<AllTypes partner={partner}/>}/>
                        <Route path="/reisearten/:type" element={<Type partner={partner}/>}/>
                        <Route path="/reise/:title" element={<Trip partner={partner}/>}/>
                        <Route path="/reise-vorschau/:tId/" element={<DraftTrip partner={partner}/>}/>
                        <Route path="/reisekalender" element={<Calendar partner={partner}/>}/>
                        <Route path="/reisebuchung/:title" element={<Checkout partner={partner}/>}/>

                        <Route path="informationen/rkv" element={<RKV partner={partner}/>}/>
                        <Route path="informationen/reisebedingungen" element={<Condition partner={partner}/>}/>
                        <Route path="informationen/zahlungsweisen" element={<Payment partner={partner}/>}/>
                        <Route path="informationen/faq" element={<FAQ partner={partner}/>}/>

                        <Route path="/datenschutz" element={<Privacy partner={partner}/>}/>
                        <Route path="/impressum" element={<Imprint partner={partner}/>}/>
                        <Route path="/kontakt" element={<Contact partner={partner}/>}/>
                        <Route path="/karriere" element={<Career partner={partner}/>}/>
                        <Route path="/referenzen" element={<References partner={partner}/>}/>
                        <Route path="/booked" element={<Booked/>}/>
                        <Route path="/newsletter-bestaetigung" element={<NewsletterConfirm/>}/>
                        <Route path="/newsletter-abmeldung" element={<NewsletterUnsubscribe/>}/>
                        {/*<Route path='*' element={<NotFound/>}/>*/}
                        <Route path='*' element={<Main/>}/> {/*todo: revert*/}
                        <Route path='/notfound' element={<NotFound/>}/>
                        <Route path='/login' element={<Login partner={partner}/>}/>
                        <Route path='/logout' element={<Logout/>}/>
                        <Route element={<ProtectedRoute/>}>
                            <Route path="/dashboard" element={<Dashboard partner={partner}/>}/>
                        </Route>
                    </Routes>
                    <Footer partner={partner}/>
                </AxiosInterceptor>
            </AuthProvider>
        </>

    );
}

export default App;
