import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {formatCurrency, localizeDate} from "../utils/utils";
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import "datatables.net-dt/css/dataTables.dataTables.css";
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.html5';
import jszip from 'jszip';
import pdfmake from 'pdfmake';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import languageDe from 'datatables.net-plugins/i18n/de-DE.mjs';

DataTable.use(DT);
DT.Buttons.jszip(jszip);
DT.Buttons.pdfMake(pdfmake);

const YearlyReport = ({report, isLoading}) => {

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const [columns] = useState([
        {
            data: 'Abflugtermin', footer: 'Gesamt:', render: function (data) {
                return localizeDate(data);
            }
        },
        {data: 'VorgangNummer',},
        {data: 'AgenturName'},
        {data: 'Reisetitel'},
        {
            data: 'AgenturProvision', render: function (data) {
                return formatCurrency(data);
            }
        },
        {
            data: 'AgenturUmsatz', render: function (data) {
                return formatCurrency(data);
            }
        },
    ]);

    useEffect(() => {
    }, [])

    return (
        <div>
            {report.months?.length > 0 && !isLoading &&

                <div>
                    <p>
                        <span> Gesamtsumme im abgefragten Zeitraum: {localizeDate(report.from)} -{localizeDate(report.to)} </span>
                        <span> Provision: {formatCurrency(report.sumAgProv)} |
                                                     Umsatz: {formatCurrency(report.sumUmsatz)} </span>
                    </p>
                </div>
            }

            {report.months?.map((month) =>
                <>
                    <h1>{month.name}</h1>
                    <DataTable
                        options={
                            {
                                language: languageDe,
                                responsive: true,
                                select: true,
                                pageLength: -1,
                                lengthMenu: [[50, 100, 500, 1000, -1], [50, 100, 500, 1000, "All"]],
                                footerCallback: function (row, data, start, end, display) {
                                    let api = this.api();
                                    // Update footer
                                    api.column(4).footer().innerHTML = formatCurrency(month.sumAgProvPerM);
                                    api.column(5).footer().innerHTML = formatCurrency(month.sumUmsatzPerM);
                                },
                                layout: {
                                    bottomStart: 'buttons',
                                },
                            }
                        }
                        data={month.rows} columns={columns} className="display">
                        <thead>
                        <tr>
                            <th>Abreise</th>
                            <th>Kontingent</th>
                            <th>Reise</th>
                            <th>Abreise</th>
                            <th>Provision</th>
                            <th>Umsatz</th>
                        </tr>
                        </thead>
                    </DataTable>
                </>
            )}

        </div>
    )
}

export default YearlyReport;